







import { Component, Vue } from 'vue-property-decorator';
import LinkedGoalsViewModel
  from '@/vue-app/view-models/allianz-dashboard/goals/linked-goals-view-model';

@Component({
  name: 'LinkedGoals',
  components: {
    GoalsUndefinedCard: () => import('@/vue-app/components/allianz-dashboard/goals/GoalsUndefinedCard.vue'),
    LinkGoalsCard: () => import('@/vue-app/components/allianz-dashboard/goals/LinkGoalsCard.vue'),
    FlagshipDefineGoalsCard: () => import('@/vue-app/components/my-investment-dashboard-v2/FlagshipDefineGoalsCard.vue'),
  },
})
export default class LinkedGoals extends Vue {
  linked_goals_view_model = Vue.observable(new LinkedGoalsViewModel());

  async created() {
    await this.linked_goals_view_model.initialize();
  }
}
