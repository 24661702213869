import TYPES from '@/types';

// Application
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class GoalsUndefinedViewModel {
  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.allianz-dashboard.goals';

  goals_completed = false;

  is_loading = true;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  obtainInvestorProfile = async () => {
    try {
      const investor_profile = await this.get_investor_profile_query.execute();
      if (investor_profile) {
        this.goals_completed = investor_profile.goals_completed;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.obtain_investor_profile'));
    }
  }

  initialize = async () => {
    await this.obtainInvestorProfile();
    this.is_loading = false;
  }
}
